import {useState} from "react";
import emailjs from '@emailjs/browser';

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * This is a custom hook that can be used to submit a form and simulate an API call
 * It uses Math.random() to simulate a random success or failure, with 50% chance of each
 */
const useSubmit = () => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const submit = async (data) => {
    try{
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        data,
        {publicKey: process.env.REACT_APP_EMAILJS_USER_ID}
      )
      .then(() => {
        console.log('SUCCESS!');
        setResponse({
          type: 'success',
          message: `Thanks for your submission ${data.firstName}`,
        })
      })
      .catch((error) => {
        console.error('EmailJS error:', error);
        setResponse({
          type: 'error',
          message: 'Something went wrong, please try again later!',
        })
      })
    } catch(error) {
      console.error('EmailJS error:', error);
      setResponse({
        type: 'error',
        message: 'Something went wrong, please try again later!',
      })
    } finally {
      setLoading(false);
    }
  };

  return { isLoading, response, submit };
}

export default useSubmit;
